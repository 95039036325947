<template>
  <v-dialog
    v-model="show"
    scrollable
    max-width="400px"
  >
    <v-card>
      <v-toolbar
        flat
        dense
        color="grey lighten-2"
      >
        <v-toolbar-title>
          Selecione
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            icon
            small
            @click="show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4">
        <v-row>
          <v-col>
            <v-list two-line >
              <v-list-item-group v-model="model" @change="onSelect">
                <template v-for="(item, index) in enrolled">
                  <v-list-item :key="`list-item-${index}`" :value="item.id">
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold">{{ item.name }}</v-list-item-title>
                       <v-list-item-subtitle
                        class="text--primary"
                        v-text="item.enrolledType.description"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text class="text-center">
                        {{ item.createdAt | date('DD/MM/YYYY HH:MM') }}
                        <br>
                        <template v-if="item.showedUpDate">
                          {{ item.showedUpDate | date('DD/MM/YYYY HH:MM') }}
                        </template>
                        <template v-else>
                          Sem presença
                        </template>
                      </v-list-item-action-text>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider
                    v-if="index < enrolled.length - 1"
                    :key="`divider-${index}`"
                  ></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {

    props: {
      value: {
        type: Boolean,
        default: false,
      },
      enrolled: {
        type: Array,
        default: [],
      },
    },

    data () {
      return {
        model: null
      }
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },

    methods: {
      onSelect (enrolledId) {
        if (enrolledId) this.$emit('select', enrolledId)
      }
    },

  }
</script>
